import React from 'react';
import classNames from 'classnames';
import {kebabCaseToPascalCase} from 'utils';
import BackgroundImage from 'Components/BackgroundImage';
import s from './CardArticle.module.scss';

const CardArticle = ({
    link = {},
    image = {},
    label = '',
    title = '',
    text = '',
    textColor = '',
    backgroundColor = '',
    backgroundColorHex = '',
    onClick = null,
}) => {
    const url = link ? (link.href || link.url || link.mail) : '';
    const href = link && link.hash ? `${url.replace(/\/$/, '')}/#${link.hash}` : url;

    const hasImage = Boolean(image && image.src);

    const bgModifier = kebabCaseToPascalCase(backgroundColor);

    // Same as sensus variable in scss
    const lightBgs = [
        'Gray', 'Red40', 'Red20', 'Blue40', 'Blue20', 'Purple40', 'Purple20',
        'Green40', 'Green20', 'Yellow', 'Yellow40', 'Yellow20', 'GavleborgGreen',
        'GavleborgTeal', 'GavleborgPink', 'GavleborgYellow',
    ];

    const hasTextColor = !backgroundColor && textColor;
    const bgIsLight = backgroundColor && lightBgs.includes(bgModifier);
    const bgIsDark = (backgroundColor && !lightBgs.includes(bgModifier));
    const isBlackTextColor = (hasTextColor && textColor === 'black') || bgIsLight;
    const isWhiteTextColor = (hasTextColor && textColor === 'white') || bgIsDark;

    const style = {};
    if(backgroundColorHex && !backgroundColor) {
        style.backgroundColor = backgroundColorHex;
    }

    const classes = classNames(
        s['Root'],
        {[s['Root--NoImage']]: !hasImage},
        {[s[`Root--Background${bgModifier}`]]: backgroundColor },
        {[s['Root--BgHex']]: !backgroundColor && backgroundColorHex },
        {[s['Root--WhiteText']]: isWhiteTextColor},
        {[s['Root--BlackText']]: isBlackTextColor},
    );

    return (
        <article className={classes}>
            <a
                href={href}
                className={s['Root__Link']}
                aria-label={link && link.text ? link.text : title}
                onClick={(e) => onClick(e)}
                style={style}
            />
            {hasImage && (
                <BackgroundImage
                    image={image}
                    className={s['Root__Image']}
                    key={image.id}
                />
            )}
            <div className={s['Root__Content']}>
                {label &&
                    <div className={s['Root__Label']}>{label}</div>
                }
                <h2 className={s['Root__Title']}>{title}</h2>
                <p className={s['Root__Text']}>{text}</p>
                <div className={s['Root__ArrowWrapper']}>
                    {link.text && 
                        <span className={s['Root__ArrowText']}>
                            {link.text}
                        </span>
                    }
                    <span className={s['Root__Arrow']}></span>
                </div>
            </div>
        </article>
    );
};

export default CardArticle;
