import React from 'react';
import CardArticle from 'Components/CardArticle';
import Title from 'Components/Title';
import s from './ArticleList.module.scss';

const ArticleList = ({ tag, title, items }) => {
    const hasHeader = Boolean(tag || title);

    return(
        <section className={s['Root']}>
            <div className={s['Root__Container']}>
                {hasHeader && (
                    <Title title={title} tag={tag} />
                )}
                {items && (
                    <ul className={s['Root__List']}>
                        {items.map((entry, index) => (
                            <li className={s['Root__Item']} key={index}>
                                <CardArticle {...entry} />
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </section>
    );
};

export default ArticleList;
